@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,700;0,800;0,900;1,300;1,400&display=swap');
@import "mq";

$black: #000000;
$white: #ffffff;
$red: #ff0048;
$violet: #3100bd;
$orange: #ff6600;
$green: #00b80f;
$footer-text: #9d9d9d;
$purple: #93005d;
$yellow: #ffe500;

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 10px;
  scroll-behavior: smooth;
}
body {
  background: white;
}
h1, h2, h3, h4, h5, h6, p, a, label, li, input, select, button {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}
h1 {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.2;
}
h2 {
  font-size: 3.6rem;
  font-weight: 900;
  line-height: 1.2;
}
h3 {
  font-size: 3.6rem;
  font-weight: 900;
}
h4 {
  font-size: 3.6rem;
  font-weight: 900;
  line-height: 1.2;
  @include media(">=phone", "<=800px") {
    font-size: 2.4rem;
  }
}
h5 {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6;
}
p {
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.6;
}

.vspacer100 {
  display: block;
  width: 100%;
  height: 100px;
  @include media(">=phone", "<=tablet") {
    height: 50px;
  }
}
.vspacer50 {
  display: block;
  width: 100%;
  height: 50px;
  @include media(">=phone", "<=tablet") {
    height: 25px;
  }
}

.orange {color: $orange}
.violet {color: $violet}
.red {color: $red}
.green {color: $green}
.purple {color: $purple}

// HEADER
.no-webp header {
  background: url('../img/header_bgr.jpg') top center no-repeat;
  background-size: cover;
}
.webp header {
  background: url('../img/header-bgr.webp') top center no-repeat;
  background-size: cover;
}
header {
  position: relative;
  width: 98%;
  height: 85vh;
  margin: 2vh auto 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 3.2px 12.7px rgba(0, 0, 0, 0.035), 
              0 8.8px 23.7px rgba(0, 0, 0, 0.05),
              0 21.1px 37.3px rgba(0, 0, 0, 0.065),
              0 70px 80px rgba(0, 0, 0, 0.1);
  border-radius: 4vw;
  -webkit-border-radius: 4vw;
  -moz-border-radius: 4vw;
  -ms-border-radius: 4vw;
  -o-border-radius: 4vw;
  @include media("<=800px") {
    justify-content: center;
    align-items: flex-end;
    margin: 0 auto;
  }
  @include media(">=phone", "<=tablet") {
    width: 90vw;
    height: 95vh;
    margin-top: 2.5vh;
    justify-content: center;
    align-items: flex-end;
    box-shadow: 0 2.2px 12.7px rgba(0, 0, 0, 0.035), 
                0 5.8px 23.7px rgba(0, 0, 0, 0.05),
                0 16.1px 37.3px rgba(0, 0, 0, 0.065),
                0 50px 60px rgba(0, 0, 0, 0.1);
  }
  .menu-icon {
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 30px;
    display: none;
    width: 30px;
    height: 4px;
    background: $yellow;
            border-radius: 2px;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
        -ms-border-radius: 2px;
         -o-border-radius: 2px;
    transition: .2s ease-in-out;
    -webkit-transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -ms-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    &::before,
    &::after {
      content: "";
      width: 30px;
      height: 4px;
      background: $yellow;
      position: absolute;
      border-radius: 2px;
      -webkit-border-radius: 2px;
         -moz-border-radius: 2px;
          -ms-border-radius: 2px;
           -o-border-radius: 2px;
    }
    &::before {
      top: -10px;
    }
    &::after {
      top: 10px;
    }
    @include media(">=phone", "<=tablet") {
      display: block;
    }
  }
  .menu-icon.menu-open {
    background: transparent;
    &::before {
      top: 0;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
    }
    &::after {
      top: 0;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
    }
  }
  .login-modal-mobile {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15vh 3rem 0;
    background: rgba(0,0,0,.95);
    border-radius: 4vw;
    -webkit-border-radius: 4vw;
    -moz-border-radius: 4vw;
    -ms-border-radius: 4vw;
    -o-border-radius: 4vw;
    .lmm-content,
    .lmm-input-row {
      display: flex;
      flex-direction: column;
      .lmm-input-row {
        padding: 3vh 0;
        p {
          font-size: 2.5rem;
          font-weight: 800;
          line-height: 1.2;
          color: $yellow;
        }
        input {
          color: $white;
          font-size: 2rem;
          font-weight: 400;
          background: transparent;
          border: none;
          border-bottom: 1px solid $white;
          padding: 2vh 0;
        }
        a {
          margin-top: 3vh;
          color: $white;
          font-size: 1.5rem;
          font-weight: 400;
          opacity: 0.6;
          text-decoration: none;
        }
      }
    }
    button {
      width: 100%;
      margin-top: 2vh;
      padding: 1rem;
      font-size: 2rem;
      font-weight: 800;
      color: $black;
      border: none;
      background: $yellow;
      border-radius: 4rem;
      -webkit-border-radius: 4rem;
      -moz-border-radius: 4rem;
      -ms-border-radius: 4rem;
      -o-border-radius: 4rem;
    }
  }
  .fp-modal-m {
    display: none;
    input {
      width: 100%;
      color: $white;
      font-size: 2rem;
      font-weight: 300;
      background: transparent;
      border: none;
      border-bottom: 1px solid $white;
      padding: 2vh 0;
    }
    button {
      width: 100%;
      margin-top: 2vh;
      padding: 1rem;
      font-size: 2rem;
      font-weight: 800;
      color: $black;
      border: none;
      background: $yellow;
      border-radius: 4rem;
      -webkit-border-radius: 4rem;
      -moz-border-radius: 4rem;
      -ms-border-radius: 4rem;
      -o-border-radius: 4rem;
    }
  }
  .lmm-active {
    display: block;
  }

  .header-content {
    width: 30%;
    padding-right: 10vw;
    @include media(">=desktop", "<1400px") {
      width: 50%;
      padding-right: 5vw;
    }
    @include media(">=800px", "<=desktop") {
      width: 50%;
      padding-bottom: 10vh;
    }
    @include media("<=800px") {
      width: 60%;
      padding-bottom: 10vh;
    }
    @include media(">=phone", "<=tablet") {
      width: 80%;
    }
    img {
      width: 50%;
      margin-bottom: 5vh;
      @include media(">=phone", "<=800px") {
        width: 70%;
      }
    }
    h1 {
      margin-bottom: 1rem;
      color: #fff300;
    }
    h5 {
      color: $white;
    }
    h5 {
      margin-bottom: 2rem;
    }
    a {
      display: inline-block;
      padding: 1.2rem 2.4rem;
      font-size: 1.6rem;
      font-weight: 800;
      text-decoration: none;
      color: #3c002a;
      background: rgb(193,255,0);
      background: linear-gradient(90deg, rgba(193,255,0,1) 0%, rgba(255,229,0,1) 100%);
      border-radius: 3rem;
      -webkit-border-radius: 3rem;
      -moz-border-radius: 3rem;
      -ms-border-radius: 3rem;
      -o-border-radius: 3rem;
      transition: all .5s ease-out;
      -webkit-transition: all .5s ease-out;
      -moz-transition: all .5s ease-out;
      -ms-transition: all .5s ease-out;
      -o-transition: all .5s ease-out;
      &:hover {
        cursor: pointer;
        background: linear-gradient(90deg, rgba(255,229,0,1) 0%, rgba(193,255,0,1) 100%);
        transform: scale(1.025, 1.025);
        -webkit-transform: scale(1.025, 1.025);
        -moz-transform: scale(1.025, 1.025);
        -ms-transform: scale(1.025, 1.025);
        -o-transform: scale(1.025, 1.025);
      }
    }
    a.active {
      background: $red;
    }
  }
}

// CONTENT
section {
  @include media(">desktop", "<1370px") {
    width: 80% !important;
  }
  @include media(">=800px", "<desktop") {
    width: 80% !important;
  }
}
.feature {
  width: 65%;
  margin: 0 auto;
  padding: 5vh 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media("<=800px") {
    width: 90%;
    flex-direction: column;
  }
  .boximage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5vw;
    @include media(">=phone", "<=tablet") {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2rem;
    }
    img, picture {
      width: 30vw;
      @include media(">=phone", "<=800px") {
        width: 100%;
      }
    }
  }
  #right-side {
    order: 2;
    margin-left: 10vw;
    // @include media(">=tablet", "<desktop") {
    //   order: 1 !important;
    //   margin-left: 0;
    // }
    // @include media("<=tablet") {
    //   order: 1 !important;
    //   margin-left: 0;
    // }
  }
  .feature-copy {
    h2 {
      line-height: 1.3;
      margin-bottom: 2rem;
      @include media("<=800px") {
        margin-bottom: 1rem;
      }
    }
  }
}
.centered {
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include media(">desktop", "<1370px") {
    width: 80%;
  }
  @include media(">=800px", "<desktop") {
    width: 80%;
  }
  @include media(">=phone", "<=tablet") {
    width: 90%;
    text-align: left;
  }
  h2 {
    margin-bottom: 2vh;
  }
  p {
    margin-bottom: 5vh;
  }
  #couple {
    width: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media(">=phone", "<=800px") {
      width: 80%;
    }
    img {
      width: 100%;
    }
    #img-right {
      margin-left: -50px;
    }
  }
  a {
    display: block;
    padding: 2rem 4rem;
    font-size: 1.6rem;
    font-weight: 800;
    text-decoration: none;
    color: $white;
    background: rgb(193,255,0);
    background: linear-gradient(35deg, rgba(0,200,20,1) 0%, rgb(0, 230, 90) 100%);
    border-radius: 3rem;
    -webkit-border-radius: 3rem;
    -moz-border-radius: 3rem;
    -ms-border-radius: 3rem;
    -o-border-radius: 3rem;
    box-shadow:
    0 6px 6px rgba(0, 0, 0, 0.1),
    0 22px 20px rgba(0, 0, 0, 0.1),
    0 80px 60px rgba(0, 0, 0, 0.08);
    transition: all .5s ease-out;
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -ms-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    &:hover {
      cursor: pointer;
      background: linear-gradient(135deg, rgba(0,230,90,1) 0%, rgba(0,200,20,1) 100%);
      transform: scale(1.025, 1.025);
      -webkit-transform: scale(1.025, 1.025);
      -moz-transform: scale(1.025, 1.025);
      -ms-transform: scale(1.025, 1.025);
      -o-transform: scale(1.025, 1.025);
    }
  }
}

// FOOTER
footer {
  width: 65vw;
  margin: 0 auto;
  padding: 2vh 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p, a {
    font-size: 1.2rem;
    color: $footer-text;
  }
  ul {
    margin: 0;
    padding: 0; 
    li {
      display: inline-block;
      a {
        margin-left: 2vw;
        text-decoration: none;
        &:hover {
          color: $purple;
        }
      }
    }
  }
}

// MODAL
.login-modal-mobile{}
.login-modal {
  position: absolute;
  top: 20px;
  right: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  .login-modal-content {
    display: none;
    justify-content: flex-end;
    align-items: flex-start;
    .input-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 1rem;
      input {
        width: 15rem;
        padding: 1rem;
        color: $yellow;
        font-size: 1.4rem;
        font-weight: 300;
        background: transparent;
        border: none;
        border-bottom: 1px solid $yellow;
        &::placeholder {
          color: $yellow;
        }
      }
      a {
        padding: 1rem;
        color: $white;
        font-size: 1.2rem;
        text-decoration: none;
        opacity: .6;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .fp-modal {
    position: absolute;
    right: 120px;
    display: none;
    justify-content: flex-end;
    align-items: flex-start;
    input {
      width: 15rem;
      padding: 1rem;
      color: $yellow;
      font-size: 1.4rem;
      font-weight: 300;
      background: transparent;
      border: none;
      border-bottom: .5px solid $yellow;
      &::placeholder {
        color: $yellow;
      }
    }
  }
  .activate {
    display: flex;
  }
  button {
    margin-left: 1rem;
    padding: 1rem 2.5rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: $yellow;
    background: transparent;
    border: 1px solid $yellow;
            border-radius: 5rem;
    -webkit-border-radius: 5rem;
       -moz-border-radius: 5rem;
        -ms-border-radius: 5rem;
         -o-border-radius: 5rem;
            transition: all .2s cubic-bezier(.59,.01,.6,1);
    -webkit-transition: all .2s cubic-bezier(.59,.01,.6,1);
       -moz-transition: all .2s cubic-bezier(.59,.01,.6,1);
        -ms-transition: all .2s cubic-bezier(.59,.01,.6,1);
         -o-transition: all .2s cubic-bezier(.59,.01,.6,1);
    &:hover {
      cursor: pointer;
      color: $black;
      background: $yellow;
    }
  }
  @include media(">=phone", "<=tablet") {
    display: none;
  }
}

.reg-modal {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  z-index: 5;
  
  .modal-content {
    position: relative;
    width: 40vw;
    padding: 4rem;
    background: $white;
    border-radius: 4rem;
    -webkit-border-radius: 4rem;
    -moz-border-radius: 4rem;
    -ms-border-radius: 4rem;
    -o-border-radius: 4rem;
    background: rgb(193,255,0);
    background: linear-gradient(90deg, rgba(255,230,0,1) 0%, rgb(255, 230, 0) 100%);
    @include media(">=desktop", "<=1200px") {
      width: 60vw;
    }
    @include media(">=400px", "<=tablet") {
      width: 70vw;
      padding: 2rem;
      border-radius: 1.6rem;
      -webkit-border-radius: 1.6rem;
      -moz-border-radius: 1.6rem;
      -ms-border-radius: 1.6rem;
      -o-border-radius: 1.6rem;
    }
    @include media(">=phone", "<=400px") {
      width: 90vw;
      padding: 1rem;
      border-radius: 1.2rem;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      -ms-border-radius: 1.2rem;
      -o-border-radius: 1.2rem;
    }
    a {
      display: inline-block;
      position: absolute;
      top: -40px;
      right: -40px;
      padding: 1.5rem 1.75rem;
      color: rgba(255,229,0,1);
      font-size: 2rem;
      font-weight: 900;
      line-height: 1;
      background: transparent;
      border: 2px solid rgba(255,229,0,1);
      border-radius: 3rem;
      -webkit-border-radius: 3rem;
      -moz-border-radius: 3rem;
      -ms-border-radius: 3rem;
      -o-border-radius: 3rem;
      text-decoration: none;
      transition: all .2s cubic-bezier(.59,.01,.6,1);
      -webkit-transition: all .2s cubic-bezier(.59,.01,.6,1);
      -moz-transition: all .2s cubic-bezier(.59,.01,.6,1);
      -ms-transition: all .2s cubic-bezier(.59,.01,.6,1);
      -o-transition: all .2s cubic-bezier(.59,.01,.6,1);
      &:hover {
        color: $black;
        background: rgba(255,229,0,1);
      }
      @include media(">=phone", "<=800px") {
        top: 0;
        right: 0;
        color: black;
        border: none;
      }
    }
    h4 {
      @include media(">=phone", "<=tablet") {
        margin-bottom: 2rem;
      }
    }
    .reg-form {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 5vh;
      @include media(">=phone", "<=800px") {
        flex-direction: column;
      }
      .col {
        width: 40%;
        &:first-of-type {
          margin-right: 4vw;
        }
        @include media(">=phone", "<=800px") {
          width: 100%;
        }
      }
      // INPUTS
      .input-group {
        position: relative;
        margin: 3rem 0;
        &:first-of-type {
          margin-top: 0;
        }
      }
      .input-group.date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        p {
          font-size: 1.4rem;
          padding-left: 5px;
        }
        .selects {
          width: 100%;
          display: flex;
          justify-content: space-between;
          select {
            appearance: none;
            -webkit-appearance: none;
            // width: 30%;
            padding: 10px 10px 10px 5px;
            display: inline-block;
            font-size: 1.8rem;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $black;
            background: transparent;
          }
        }
      }
      
      input {
        font-size: 1.8rem;
        padding: 10px 0 10px 5px;
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid $black;
        background: transparent;
      }
      
      input:focus {
        outline: none;
      }

      label {
        color: $black;
        font-size: 1.8rem;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
      }
      
      input:focus ~ label,
      input:valid ~ label {
        top: -20px;
        font-size: 14px;
        color: $black;
      }
      
      .bar {
        position: relative;
        display:block;
        width: 100%;
      }
      
      .bar:before,
      .bar:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: $black;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
      }
      
      .bar:before {
        left: 50%;
      }
      
      .bar:after {
        right: 50%;
      }
      
      input:focus ~ .bar:before,
      input:focus ~ .bar:after {
        width: 50%;
      }
      
      // .highlight {
      //   position: absolute;
      //   height: 60%;
      //   width: 100px;
      //   top: 25%;
      //   left: 0;
      //   pointer-events: none;
      //   opacity: 0.5;
      // }
      
      input:focus ~ .highlight {
        -webkit-animation: inputHighlighter 0.3s ease;
        -moz-animation: inputHighlighter 0.3s ease;
        animation: inputHighlighter 0.3s ease;
      }
      
      /* animations */
      @-webkit-keyframes inputHighlighter {
        from { background: #4285f4; }
        to   { width: 0; background: transparent; }
      }
      @-moz-keyframes inputHighlighter {
        from { background: #4285f4; }
        to   { width: 0; background: transparent; }
      }
      @keyframes inputHighlighter {
        from { background: #4285f4; }
        to   { width: 0; background: transparent; }
      }
      button {
        display: inline-block;
        width: 100%;
        padding: 1.2rem 2.4rem;
        font-size: 1.6rem;
        font-weight: 800;
        text-decoration: none;
        color: $white;
        background: $black;
        border: none;
        border-radius: 3rem;
        -webkit-border-radius: 3rem;
        -moz-border-radius: 3rem;
        -ms-border-radius: 3rem;
        -o-border-radius: 3rem;
        transition: all .5s ease-out;
        -webkit-transition: all .5s ease-out;
        -moz-transition: all .5s ease-out;
        -ms-transition: all .5s ease-out;
        -o-transition: all .5s ease-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.025, 1.025);
          -webkit-transform: scale(1.025, 1.025);
          -moz-transform: scale(1.025, 1.025);
          -ms-transform: scale(1.025, 1.025);
          -o-transform: scale(1.025, 1.025);
        }
        @include media(">=phone", "<=800px") {
          width: 100%;
        }
      }
    }
  }
}
.modal-open {
  display: flex;
  opacity: 1;
}